import dynamic from 'next/dynamic';
import { useEffect } from 'react';

import useMediaQuery from 'hooks/useMediaQuery';
import useScrollControl from 'hooks/useScrollControl';
import useWishlist from 'hooks/useWishlist';

import { subtractTimeFromNow } from 'lib/date';
import { getFromLocalStorage } from 'lib/localStorage';

import LOCAL_STORAGE from 'constants/localStorage';

const ActionSheet = dynamic(
  () => import('components/Wishlist/WishlistMaintenanceActionSheet'),
  { ssr: false }
);

const isKeywordUpdatedMoreThanSixMonths = (keywordLastUpdate) => {
  const diffFromNowInMonths = subtractTimeFromNow(keywordLastUpdate, {
    unit: 'month',
  });

  return diffFromNowInMonths >= 6;
};

const WishlistMaintenanceActionSheet = () => {
  const { isLg } = useMediaQuery();
  const { offerWishlist } = useWishlist();
  const { showScrollable } = useScrollControl();

  useEffect(() => {
    if (!isLg) {
      return;
    }

    const isActionSheetAlreadyInteracted =
      getFromLocalStorage(
        LOCAL_STORAGE.IS_WISHLIST_MAINTENANCE_ALREADY_INTERACTED
      ) ?? false;

    if (isActionSheetAlreadyInteracted) {
      return;
    }

    const hasKeywordWithLastUpdateMoreThanSixMonths = offerWishlist?.items.some(
      ({ keywordDate, keywordUpdate }) =>
        isKeywordUpdatedMoreThanSixMonths(keywordUpdate || keywordDate)
    );

    if (!hasKeywordWithLastUpdateMoreThanSixMonths) {
      return;
    }

    showScrollable(ActionSheet, { isOpen: true });
  }, [isLg]);

  return null;
};

export default WishlistMaintenanceActionSheet;
