import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import { userQueryKeys } from 'lib/queryKeys';

import { useUser } from 'providers/UserProvider';

import {
  delOfferCategoryFilter,
  delOfferSubcategoryFilter,
  getUserOfferFilters,
  postOfferCategoryFilter,
  postOfferSubcategoryFilter,
} from 'services/offer';

import useLocalStorageState from './useLocalStorageState';
import useQueryClient from './useQueryClient';

import LOCAL_STORAGE from 'constants/localStorage';

const CATEGORY = 'category';
const SUBCATEGORY = 'subcategory';
const INTERNATIONAL_OFFERS = 'international_offers';

const useOffersFilters = () => {
  const { isLoggedIn, loadingLoggedInUser } = useUser();
  const queryClient = useQueryClient();
  const [isInternationalOffersActive, setInternationalOffersActive] =
    useLocalStorageState(LOCAL_STORAGE.IS_INTERNATIONAL_OFFERS_ACTIVE, true);

  const { data: offersFiltersState } = useQuery({
    queryKey: userQueryKeys.offerFilters(),
    queryFn: ({ signal }) => getUserOfferFilters({ signal }),
    enabled: !loadingLoggedInUser && isLoggedIn,
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  const getOffersFilters = () => {
    const defaultResult = {
      categories: [],
      subcategories: [],
      isInternationalOffersActive: true,
      lastAction: null,
    };

    if (!offersFiltersState) {
      return defaultResult;
    }

    return {
      isInternationalOffersActive:
        isInternationalOffersActive ??
        defaultResult.isInternationalOffersActive,
      ...offersFiltersState,
    };
  };

  const addOffersFilter = useCallback(
    async ({ type, id, name }) => {
      const nextOffersFilters = {
        ...getOffersFilters(),
        lastAction: 'ADD',
      };

      switch (type) {
        case INTERNATIONAL_OFFERS:
          // eslint-disable-next-line max-len
          nextOffersFilters.isInternationalOffersActive =
            !nextOffersFilters.isInternationalOffersActive;
          setInternationalOffersActive((prevState) => !prevState);
          break;

        case CATEGORY:
          await postOfferCategoryFilter({
            categoryId: id,
          });
          nextOffersFilters.categories = [
            ...nextOffersFilters.categories,
            {
              categoryId: id,
              categoryName: name,
            },
          ];
          break;

        case SUBCATEGORY:
          await postOfferSubcategoryFilter({
            subcategoryId: id,
          });
          nextOffersFilters.subcategories = [
            ...nextOffersFilters.subcategories,
            {
              subcategoryId: id,
              subcategoryName: name,
            },
          ];
          break;

        default:
          break;
      }

      queryClient.setQueryData(userQueryKeys.offerFilters(), nextOffersFilters);
    },
    [getOffersFilters]
  );

  const delOffersFilter = useCallback(
    async ({ type, id }) => {
      const nextOffersFilters = {
        ...getOffersFilters(),
        lastAction: 'DELETE',
      };

      switch (type) {
        case INTERNATIONAL_OFFERS:
          // eslint-disable-next-line max-len
          nextOffersFilters.isInternationalOffersActive =
            !nextOffersFilters.isInternationalOffersActive;
          setInternationalOffersActive((prevState) => !prevState);
          break;

        case CATEGORY:
          await delOfferCategoryFilter({
            categoryId: id,
          });
          // eslint-disable-next-line max-len
          nextOffersFilters.categories = nextOffersFilters.categories.filter(
            (category) => category.categoryId !== id
          );
          break;

        case SUBCATEGORY:
          await delOfferSubcategoryFilter({
            subcategoryId: id,
          });
          // eslint-disable-next-line max-len
          nextOffersFilters.subcategories =
            nextOffersFilters.subcategories.filter(
              (subcategory) => subcategory.subcategoryId !== id
            );
          break;

        default:
          break;
      }

      queryClient.setQueryData(userQueryKeys.offerFilters(), nextOffersFilters);
    },
    [getOffersFilters]
  );

  return {
    offersFiltersState,
    delOffersFilter,
    addOffersFilter,
    getOffersFilters,
  };
};

export default useOffersFilters;
