import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { noop } from 'lib/utils';

import Text from '../Text';

const typeStyles = {
  primary:
    'text-primary-400 dark:text-primary-200 fill-primary-400 dark:fill-primary-200',
  secondary: 'text-neutral-low-300 dark:text-neutral-high-200',
};

const typeActiveStyles = {
  primary: 'hover:text-primary-400 dark:hover:text-primary-200',
  secondary: 'hover:text-neutral-high-500 dark:hover:text-neutral-high-500',
};

const typeHoverStyles = {
  primary:
    'hover:text-primary-300 dark:hover:text-primary-100 fill-primary-300 dark:fill-primary-100',
  secondary: 'hover:text-neutral-low-100 dark:hover:text-neutral-high-100',
};

const Link = forwardRef(
  (
    {
      children,
      className,
      href,
      size = 'size1',
      type = 'primary',
      onClick = noop,
      ...rest
    },
    ref
  ) => {
    // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
    const htmlMarkup = !href ? 'button' : 'a';

    return (
      <Text
        ref={ref}
        as={htmlMarkup}
        className={twMerge(
          'inline-flex w-fit items-start no-underline hover:underline active:underline',
          typeStyles[type],
          typeActiveStyles[type],
          typeHoverStyles[type],
          className
        )}
        href={href}
        size={size}
        onClick={onClick}
        {...rest}
      >
        {children}
      </Text>
    );
  }
);

Link.displayName = 'Link';

Link.propTypes = {
  /**
   * String or component to render
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /**
   * A valid URL that the anchor points to
   */
  href: PropTypes.string,
  /**
   * Renders Link in different sizes
   */
  size: PropTypes.oneOf(['size1', 'size2', 'size3', 'size4']),
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Renders `target` HTML attribute
   */
  target: PropTypes.string,
  /**
   * Whether link has primary or secondary variant
   */
  type: PropTypes.oneOf(['primary', 'secondary']),
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback to be executed on click
   */
  onClick: PropTypes.func,
};

export default Link;
