/**
 *  This components was build on top of the selective hydration pattern, which
 * makes possible hydrate components only when the component is to be visible
 * to the browser viewport or when main-thread was in idle status. When applied
 * to a CPU-bound or bigger component, this improves several performance
 * metrics, like TTI and TBT.
 *
 *  In recent versions of React, exists some built-in APIs that already
 * implements the selective hydration pattern, like `<Suspense />`, but we
 * encountered several difficulties to use these React APIs, like streaming,
 * moment when wrapped component is hydrated (particularly, the choose to
 * hydrate the wrapped component when is marked as resolved, can make some
 * drawbacks, increasing the time running the long processing of a page load,
 * for exemple) and  the upgrade  to the React v18.
 *
 *  These design decisions of React and the "issues" encountered forces we build
 * this in-house solution. This solution isn't 100% perfect, but for our
 * purpose it's good enough.
 */
import PropTypes from 'prop-types';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { isBrowser, markup } from 'lib/utils';

const LazyHydrationOnServer = ({ children, fallback }) => (
  <section>{fallback || children}</section>
);

const LazyHydrationOnClient = ({
  children,
  className,
  fallback,
  force = false,
  placeholderSize,
}) => {
  const containerRef = useRef(null);
  const [isHydrated, setHydrated] = useState(force);
  const cleanups = useRef([]);

  const cleanup = () => {
    cleanups.current.map((fn) => fn());
  };

  const hydrate = () => {
    // Remove listeners first to avoid dispatch unnecessarily
    cleanup();
    setHydrated(true);
  };

  const makeIdleHydration = () => {
    const idleCallback = requestIdleCallback(
      () => {
        requestAnimationFrame(hydrate);
      },
      { timeout: 850 }
    );

    cleanups.current.push(() => {
      cancelIdleCallback(idleCallback);
    });
  };

  const makeVisibleHydration = () => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting || !(entry.intersectionRatio > 0)) {
          return;
        }

        hydrate();
      },
      { threshold: 0 }
    );

    observer.observe(containerRef.current);
    cleanups.current.push(() => {
      observer.disconnect();
    });
  };

  useLayoutEffect(() => {
    if (force && !isHydrated) {
      hydrate();
    }
  }, [force]);

  useEffect(() => {
    if (isHydrated) {
      return;
    }

    const initEvent = () => {
      const rect = containerRef.current.getBoundingClientRect();
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;

      const isInViewport = rect.top >= 0 && rect.bottom <= viewportHeight;

      /**
       * Recently Safari rolled back their implementation of
       * `requestIdleCallback` due internal inconsistencies.
       */
      if (isInViewport && 'requestIdleCallback' in window) {
        makeIdleHydration();
      } else {
        makeVisibleHydration();
      }
    };

    initEvent();

    return () => {
      cleanup();
    };
  }, []);

  if (!isHydrated) {
    return (
      <>
        <div ref={containerRef} />
        <section
          /**
           * Hacky to React don't touch in this component in client-side until
           * threshold is intersected.
           *
           * See: https://github.com/facebook/react/issues/10923#issuecomment-338715787
           */
          dangerouslySetInnerHTML={markup('')}
          className={className}
          style={
            !fallback && placeholderSize
              ? {
                  height: placeholderSize.height,
                  width: placeholderSize.width,
                }
              : null
          }
          suppressHydrationWarning
        />
        {fallback}
      </>
    );
  }

  return children;
};

const LazyHydration = ({
  children,
  className,
  fallback,
  force = false,
  placeholderSize,
}) => {
  if (isBrowser()) {
    return (
      <LazyHydrationOnClient
        className={className}
        fallback={fallback}
        force={force}
        placeholderSize={placeholderSize}
      >
        {children}
      </LazyHydrationOnClient>
    );
  }

  // Initially mounts component in server-side for SEO purposes
  return (
    <LazyHydrationOnServer fallback={fallback}>
      {children}
    </LazyHydrationOnServer>
  );
};

LazyHydration.propTypes = {
  /**
   * The component that you want to lazy hydrate.
   */
  children: PropTypes.node.isRequired,
  /**
   * Renders a custom placeholder component until main component isn't
   * hydrated, allowing to improve crawler mechanisms like SEO, without the
   * overhead to mount main component in server-side.
   */
  fallback: PropTypes.node,
  /**
   * Forces component be hydrated as soon as possible, useful when is necessary
   * manipulates component programmatically.
   */
  force: PropTypes.bool,
  /**
   * Set the dimensions of placeholder component, reducing possible layout
   * shifts when lazy component is being hydrated.
   */
  placeholderSize: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

export default LazyHydration;
