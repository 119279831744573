import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { memo } from 'react';

import Heading from 'shopper/components/Heading';

import CarouselBox from 'components/CarouselBox';
import OfferCard from 'components/Timeline/OfferCard';

import { isFirstItem } from 'lib/array';
import { sendEvent } from 'lib/gtag';
import { offerQueryKeys, topbarQueryKeys } from 'lib/queryKeys';

import { getTopbarAd } from 'services/ads';
import { getFeaturedOffersList } from 'services/offer';

const FeaturedOffers = ({ gaEventCategory, serverFeaturedOffers, title }) => {
  const { data: topbarAd } = useQuery({
    queryKey: topbarQueryKeys.ads(),
    queryFn: ({ signal }) => getTopbarAd({ signal }),
    enabled: false,
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { data: featuredOffers } = useQuery({
    queryKey: offerQueryKeys.listByFeatured(),
    queryFn: ({ signal }) => getFeaturedOffersList({ signal }),
    enabled: false,
    initialData: serverFeaturedOffers,
  });

  const onImageClick = () =>
    sendEvent({
      action: 'product_image_featured_deals',
      category: gaEventCategory,
    });

  const onTitleClick = () =>
    sendEvent({
      action: 'product_title_featured_deals',
      category: gaEventCategory,
    });

  return (
    <>
      <div className="mb-3 flex w-full items-center lg:mb-5">
        <Heading as="p" size="size3">
          {title}
        </Heading>
      </div>
      <div className="w-full translate-y-1 lg:translate-y-0">
        <CarouselBox
          arrowsProps={{
            className: 'hidden md:hidden lg:flex xl:hidden',
            offsetX: { left: '-left-2', right: '-right-2' },
          }}
          carouselProps={{ className: 'w-full' }}
          itemProps={{
            itemSize: 92,
            slidesPerView: 1,
            snapMode: 'snap-start',
            breakpoints: {
              md: { slidesPerView: 3, itemSize: 92 },
              lg: { slidesPerView: 3, itemSize: 96 },
              xl: { slidesPerView: 4, itemSize: 96 },
            },
          }}
          list={featuredOffers}
          render={(featuredOffer, index) => (
            <OfferCard
              className="border border-neutral-high-300 dark:border-neutral-low-300 lg:border-none"
              gaEventCategory={gaEventCategory}
              offerImagePriority={!topbarAd && isFirstItem(index)}
              isRecommendedCard
              onImageClick={onImageClick}
              onTitleClick={onTitleClick}
              {...featuredOffer}
            />
          )}
          full
          showArrows
        />
      </div>
    </>
  );
};

FeaturedOffers.propTypes = {
  serverFeaturedOffers: PropTypes.arrayOf(
    PropTypes.shape({
      categoryId: PropTypes.number.isRequired,
      categorySlug: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      lastActiveOffer: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      offerComments: PropTypes.number.isRequired,
      offerId: PropTypes.number.isRequired,
      offerIsHighlight: PropTypes.bool.isRequired,
      ratings: PropTypes.shape().isRequired,
      offerOldPrice: PropTypes.number,
      offerPhoto: PropTypes.string.isRequired,
      offerPrice: PropTypes.number.isRequired,
      offerPriceType: PropTypes.string.isRequired,
      offerPublished: PropTypes.string.isRequired,
      offerSlug: PropTypes.string.isRequired,
      offerStatusName: PropTypes.string.isRequired,
      offerUserVisibility: PropTypes.string.isRequired,
      offerTags: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
        })
      ),
      offerTitle: PropTypes.string.isRequired,
      storeDomain: PropTypes.string.isRequired,
      storeId: PropTypes.number,
      storeName: PropTypes.string,
      subcategoryId: PropTypes.number,
      subcategorySlug: PropTypes.string.isRequired,
      userId: PropTypes.number.isRequired,
      userLevel: PropTypes.number,
      userName: PropTypes.string.isRequired,
      userPhoto: PropTypes.string.isRequired,
      userTypeName: PropTypes.string.isRequired,
      userUsername: PropTypes.string.isRequired,
    })
  ).isRequired,
  gaEventCategory: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default memo(FeaturedOffers);
