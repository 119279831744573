import PropTypes from 'prop-types';
import { Component } from 'react';

import { isDev } from 'lib/utils';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  async componentDidCatch(error, info) {
    this.setState({ hasError: true });

    if (isDev()) {
      // eslint-disable-next-line no-console
      console.error(info.componentStack);
      return;
    }

    const { captureException } = await import('@sentry/nextjs');

    captureException(error, { contexts: { react: info.componentStack } });
  }

  render() {
    if (this.state.hasError) {
      const { fallback: Fallback } = this.props;

      return <Fallback />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]).isRequired,
};

export default ErrorBoundary;
