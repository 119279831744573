import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

const LayoutMargin = ({
  className,
  flatBg,
  maxWidthOnMobileDevices = false,
  ...rest
}) => (
  <div
    className={twMerge(
      'relative mx-auto my-0 w-full lg:w-[64rem] lg:px-2 xl:w-[80rem]',
      maxWidthOnMobileDevices ? 'px-0' : 'px-4',
      flatBg ? 'mb-11' : 'mb-6',
      className
    )}
    {...rest}
  />
);

LayoutMargin.propTypes = {
  maxWidthOnMobileDevices: PropTypes.bool,
  flatBg: PropTypes.bool.isRequired,
};

export default LayoutMargin;
