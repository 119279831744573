import { useMutation, useQuery } from '@tanstack/react-query';
import { setCookie } from 'cookies-next';
import dayjs from 'dayjs';
import { useEffect } from 'react';

import useToast from 'hooks/useToast';

import placeholder from 'lib/placeholder';
import { offerQueryKeys } from 'lib/queryKeys';

import { useUser } from 'providers/UserProvider';

import { patchUserProfile } from 'services/user';

import useMediaQuery from './useMediaQuery';
import useQueryClient from './useQueryClient';

import COOKIES from 'constants/cookies';
import OFFER from 'constants/offer';

const useOffersViewModeQuery = ({
  initialData = null,
  ssrIsUserLogged = false,
}) => {
  const { isMd } = useMediaQuery();
  const { isLoggedIn, loadingLoggedInUser, LoggedInUser } = useUser();
  const { showToast } = useToast();
  const queryClient = useQueryClient();

  const { data: offersViewMode } = useQuery({
    queryKey: offerQueryKeys.viewMode(),
    enabled: false,
    staleTime: Infinity,
    initialData: () => {
      if (!ssrIsUserLogged) {
        return OFFER.VIEW_MODES.GRID;
      }

      /**
       * We'll fetch this state from the logged user
       * as soon as it's ready in this case.
       */
      if (ssrIsUserLogged && !initialData) {
        return null;
      }

      return initialData;
    },
    onSuccess: (currOffersViewMode) => {
      /**
       * If the user doesn't initially have the cookie,
       * here we make sure to save it for a future request where
       * it can be used as a `initialData`.
       */
      if (ssrIsUserLogged && !initialData) {
        setCookie(COOKIES.OFFERS_VIEW_MODE, currOffersViewMode, {
          expires: dayjs().add(3, 'year').toDate(),
          sameSite: true,
        });
      }
    },
  });

  const { mutate: mutateOfferViewMode } = useMutation({
    mutationFn: ({ mode, mutateOnServer = true }) => {
      if (mutateOnServer) {
        return patchUserProfile({ offerViewMode: mode });
      }
    },
    onError: () => {
      showToast({
        text: placeholder('toasts.errors.unknown'),
      });
    },
    onSuccess: (_, { mode }) => {
      queryClient.setQueryData(offerQueryKeys.viewMode(), mode);

      if (isLoggedIn && mode) {
        setCookie(COOKIES.OFFERS_VIEW_MODE, mode, {
          expires: dayjs().add(3, 'year').toDate(),
          sameSite: true,
        });
      }
    },
  });

  useEffect(() => {
    /**
     * Any screens smaller than `md` breakpoint only got the `grid` option
     * so here we make sure that if the device screen changes for however
     * reason, it keeps the source of truth updated as it will be used for
     * layout changes.
     */
    if (!isMd && offersViewMode === OFFER.VIEW_MODES.LIST) {
      mutateOfferViewMode({
        mode: OFFER.VIEW_MODES.GRID,
        mutateOnServer: false,
      });
    }
  }, [isMd]);

  useEffect(() => {
    /**
     * If the user already has a set `offersViewMode` saved,
     * fetch and replicate this to our source of truth. If not, set a
     * default value.
     */
    if (!offersViewMode && !loadingLoggedInUser && isLoggedIn) {
      queryClient.setQueryData(
        offerQueryKeys.viewMode(),
        LoggedInUser?.offerViewMode || OFFER.VIEW_MODES.GRID
      );
    }
  }, [isLoggedIn, loadingLoggedInUser]);

  return [offersViewMode, mutateOfferViewMode];
};

export default useOffersViewModeQuery;
