/**
 * TODO
 * - in some cases the scroll-y don't start with `0`. You can view this issue
 * in `OffersCarousel` of `ArticlePage`
 * - resolve minor style issues when the viewport is similar to a tablet
 * - overflow occurring in `SuggestNewOfferForm`
 */
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import Box from 'shopper/components/Box';

import useMediaQuery from 'hooks/useMediaQuery';

import ButtonArrow from './ButtonArrow';

const Dots = dynamic(() => import('./Dots'));

const AUTOPLAY_INTERVAL_SECONDS = 6 * 1000;
const DESKTOP_SCROLL_MIDDLE_GAP = 16;
const MOBILE_SCROLL_MIDDLE_GAP = 4;
const X_AXIS_SCROLL_DESKTOP_MULTIPLIER = 0.5;
const X_AXIS_SCROLL_MOBILE_MULTIPLIER = 1;

const toCarouselItemWidth = ({ slidesPerView, itemSize }) =>
  slidesPerView === 1 ? `${itemSize}%` : `calc(${itemSize}%/${slidesPerView})`;

const CarouselBox = ({
  autoPlay = false,
  arrowsProps = {},
  dots = false,
  carouselProps,
  className,
  full = false,
  itemProps,
  list,
  render,
  showArrows,
  ...rest
}) => {
  const router = useRouter();
  const [showPrevArrow, setShowPrevArrow] = useState(false);
  const [endOfScroll, setEndOfScroll] = useState(false);
  const carouselRef = useRef(null);
  const { isSm, isMd, isLg, isXl } = useMediaQuery();
  const { breakpoints, itemSize, slidesPerView, snapMode } = itemProps ?? {};

  useEffect(() => {
    const resetCarousel = () => {
      if (carouselRef.current) {
        carouselRef.current.scrollTo({ left: 0, behavior: 'smooth' });
      }
    };

    router.events.on('routeChangeStart', resetCarousel);

    return () => {
      router.events.off('routeChangeStart', resetCarousel);
    };
  }, [router]);
  const xAxisScrollMultiplier = isMd
    ? X_AXIS_SCROLL_DESKTOP_MULTIPLIER
    : X_AXIS_SCROLL_MOBILE_MULTIPLIER;

  /**
   * This function is to blame for creating some inconsistencies during
   * pagination of carousel. To calculate better the scroll, the calc needs to
   * be based on more variables, like item size in current viewport and quantity
   * of items per page.
   */
  const onScroll = useCallback(
    (multiplier) => {
      const carouselElem = carouselRef.current;

      if (slidesPerView !== 1) {
        const scrollDistance = carouselElem.clientWidth * multiplier;

        carouselElem.scrollBy({ left: scrollDistance, behavior: 'smooth' });
        return;
      }

      if (multiplier === 0) {
        carouselElem.scrollTo({
          left: 0,
          behavior: 'smooth',
        });
        return;
      }

      const { children, scrollWidth } = carouselElem;
      const isGoingToRight = multiplier > 0;
      const scrollDistance =
        (scrollWidth / children.length) * (isGoingToRight ? 1 : -1);
      const isScrollInStart = carouselElem.scrollLeft + scrollDistance <= 0;
      const isScrollInEnd =
        carouselElem.scrollLeft + carouselElem.clientWidth + scrollDistance >=
        scrollWidth;
      const isScrollInMiddle = !isScrollInStart && !isScrollInEnd;
      const gap =
        multiplier === X_AXIS_SCROLL_DESKTOP_MULTIPLIER
          ? DESKTOP_SCROLL_MIDDLE_GAP
          : MOBILE_SCROLL_MIDDLE_GAP;

      const scrollDistanceWithGap = isScrollInMiddle
        ? scrollDistance - gap
        : scrollDistance;

      carouselElem.scrollBy({
        left: scrollDistanceWithGap,
        behavior: 'smooth',
      });
    },
    [slidesPerView]
  );

  useEffect(() => {
    if (!autoPlay) {
      return;
    }

    const interval = setInterval(() => {
      onScroll(endOfScroll ? 0 : xAxisScrollMultiplier);
    }, AUTOPLAY_INTERVAL_SECONDS);

    return () => {
      clearInterval(interval);
    };
  }, [autoPlay, endOfScroll]);

  const carouselItemsStyles = useMemo(
    () => ({
      '--itemWidth': toCarouselItemWidth({ slidesPerView, itemSize }),
      /**
       * TODO: This size only exists to resolve a very specific problem into
       * `AdsCarousel` and then, after Black-Friday we need to revisit it,
       * resolving the issues in a better way.
       */
      '--itemWidthSMMD': toCarouselItemWidth({
        slidesPerView: breakpoints?.smd?.slidesPerView || slidesPerView,
        itemSize: breakpoints?.smd?.itemSize || itemSize,
      }),
      '--itemWidthMD': toCarouselItemWidth({
        slidesPerView:
          breakpoints?.md?.slidesPerView ||
          breakpoints?.smd?.slidesPerView ||
          slidesPerView,
        itemSize:
          breakpoints?.md?.itemSize || breakpoints?.smd?.itemSize || itemSize,
      }),
      '--itemWidthLG': toCarouselItemWidth({
        slidesPerView:
          breakpoints?.lg?.slidesPerView ||
          breakpoints?.md?.slidesPerView ||
          breakpoints?.smd?.slidesPerView ||
          slidesPerView,
        itemSize:
          breakpoints?.lg?.itemSize ||
          breakpoints?.md?.itemSize ||
          breakpoints?.smd?.itemSize ||
          itemSize,
      }),
      '--itemWidthXL': toCarouselItemWidth({
        slidesPerView:
          breakpoints?.xl?.slidesPerView ||
          breakpoints?.lg?.slidesPerView ||
          breakpoints?.md?.slidesPerView ||
          breakpoints?.smd?.slidesPerView ||
          slidesPerView,
        itemSize:
          breakpoints?.xl?.itemSize ||
          breakpoints?.lg?.itemSize ||
          breakpoints?.md?.itemSize ||
          breakpoints?.smd?.itemSize ||
          itemSize,
      }),
    }),
    []
  );

  const onCarouselScroll = useCallback(() => {
    const carouselElem = carouselRef.current;
    const { scrollWidth } = carouselElem;
    const isScrollInStart = carouselElem.scrollLeft <= 0;
    const isScrollInEnd =
      carouselElem.scrollLeft + carouselElem.clientWidth >= scrollWidth;

    setShowPrevArrow(!isScrollInStart);
    setEndOfScroll(isScrollInEnd);
  }, [isMd]);

  const isItemsWithFullWidth = () => {
    const mediaQueries = [isSm, isMd, isLg, isXl];
    const currMediaQueryIndex = mediaQueries.lastIndexOf(true);
    const slidesCount = carouselRef.current?.childElementCount;
    const slidesPerViewArr = [
      slidesPerView,
      breakpoints?.md?.slidesPerView,
      breakpoints?.lg?.slidesPerView,
      breakpoints?.xl?.slidesPerView,
    ];

    if (slidesPerViewArr[currMediaQueryIndex] !== undefined) {
      return slidesPerViewArr[currMediaQueryIndex] === slidesCount;
    }

    for (let i = currMediaQueryIndex; i >= 0; i--) {
      if (slidesPerViewArr[i] !== undefined) {
        return slidesPerViewArr[i] === slidesCount;
      }
    }
  };

  return (
    <Box
      className={twMerge(
        'relative bg-transparent dark:bg-transparent',
        className
      )}
      full
      {...rest}
    >
      <div
        ref={carouselRef}
        className={twMerge(
          'mx-auto my-0 flex snap-x snap-mandatory gap-x-2 overflow-y-hidden overflow-x-scroll scroll-smooth scrollbar-none',
          carouselProps?.className,
          full && 'w-full'
        )}
        onScroll={showArrows ? onCarouselScroll : null}
      >
        {list.map((item, index, array) => (
          <div
            key={`slide-${index}`}
            className={twJoin(
              /**
               * TODO: After removes SM_MD size, also removes `!important`
               * declarations
               */
              'w-[var(--itemWidth)] flex-shrink-0 md:!w-[var(--itemWidthMD)] lg:!w-[var(--itemWidthLG)] xl:!w-[var(--itemWidthXL)] [@media(min-width:380px)]:w-[var(--itemWidthSMMD)]',
              snapMode,
              isItemsWithFullWidth() && 'flex-1'
            )}
            style={carouselItemsStyles}
          >
            {render(item, index, array)}
          </div>
        ))}
      </div>
      {showArrows && showPrevArrow && (
        <ButtonArrow
          direction="left"
          onClick={() => onScroll(-xAxisScrollMultiplier)}
          {...arrowsProps}
        />
      )}
      {showArrows && !endOfScroll && (
        <ButtonArrow
          direction="right"
          onClick={() => onScroll(xAxisScrollMultiplier)}
          {...arrowsProps}
        />
      )}
      {dots && (
        <Dots
          actualPage={2}
          carouselRef={carouselRef}
          className="mt-2"
          totalOfPages={5}
        />
      )}
    </Box>
  );
};

CarouselBox.propTypes = {
  autoPlay: PropTypes.bool,
  arrowsProps: PropTypes.shape({
    display: PropTypes.array,
    offsetX: PropTypes.shape(),
  }),
  dots: PropTypes.bool,
  full: PropTypes.bool,
  itemProps: PropTypes.shape({
    breakpoints: PropTypes.shape({
      md: PropTypes.shape({
        itemSize: PropTypes.number,
        snapMode: PropTypes.string,
        slidersPerView: PropTypes.number,
      }),
      lg: PropTypes.shape({
        itemSize: PropTypes.number,
        snapMode: PropTypes.string,
        slidersPerView: PropTypes.number,
      }),
      xl: PropTypes.shape({
        itemSize: PropTypes.number,
        snapMode: PropTypes.string,
        slidersPerView: PropTypes.number,
      }),
    }),
    itemSize: PropTypes.number,
    slidesPerView: PropTypes.number,
    snapMode: PropTypes.string,
  }).isRequired,
  list: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape(), PropTypes.array])
  ).isRequired,
  render: PropTypes.func.isRequired,
};

export default CarouselBox;
