import NextLink from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { twJoin } from 'tailwind-merge';

import Box from 'shopper/components/Box';
import Button from 'shopper/components/Button';
import Icon from 'shopper/components/Icon';
import { TabNav, TabNavItem } from 'shopper/components/TabNav';
import Text from 'shopper/components/Text';

import { sendEvent } from 'lib/gtag';
import { stripQs } from 'lib/request';

import { useUser } from 'providers/UserProvider';

import HOME_OFFERS_LIST_TABS from 'constants/homeOffersListTabs';
import OFFER from 'constants/offer';

const OffersOptions = forwardRef(
  (
    {
      gaEventCategory,
      offersViewMode = null,
      onHideOffersLinkClick,
      onOffersGridViewButtonClick,
      onOffersListViewButtonClick,
    },
    ref
  ) => {
    const { isLoggedIn } = useUser();
    const router = useRouter();

    const pathname = stripQs(router.asPath);

    return (
      <Box
        ref={ref}
        className="relative mb-4 flex-row items-center justify-between px-3 pt-2"
      >
        <TabNav className="justify-center border-none">
          {HOME_OFFERS_LIST_TABS.map(({ id, title, iconName, href }) => {
            const isActive = href === pathname;

            return (
              <NextLink key={id} href={href} scroll={false} passHref>
                <TabNavItem
                  active={isActive}
                  as="a"
                  className={twJoin(
                    'flex items-center px-2 pb-4 text-sm',
                    iconName &&
                      isActive &&
                      '[&>svg]:text-primary-300 dark:[&>svg]:text-primary-200'
                  )}
                  size="size1"
                  onClick={() => {
                    sendEvent({
                      action: `sheet_${id}_offers`,
                      category: gaEventCategory,
                    });
                  }}
                >
                  <Text
                    as={isActive ? 'h1' : 'p'}
                    className={twJoin(
                      'mr-2',
                      isActive && 'text-primary-300 dark:text-primary-200'
                    )}
                    bold
                  >
                    {title}
                  </Text>
                  <Icon name={iconName} size="size1" />
                </TabNavItem>
              </NextLink>
            );
          })}
        </TabNav>
        {offersViewMode && (
          <Box className="relative -top-1 flex-row items-center justify-end">
            <div className="order-1 ml-2 hidden gap-y-2 md:order-2 md:ml-0 md:flex">
              <Button
                aria-label="Abrir modal de ocultar ofertas"
                data-test-selector="open-hide-offers-modal"
                icon={<Icon name="settings" />}
                size="size3"
                type="neutral-ghost"
                onClick={onHideOffersLinkClick}
              />
              <>
                <Button
                  aria-label="Trocar visualização das ofertas para grid"
                  data-test-selector="change-to-offers-grid-view"
                  icon={<Icon name="grid" />}
                  size="size3"
                  type={
                    offersViewMode === OFFER.VIEW_MODES.GRID
                      ? 'neutral-stroke'
                      : 'neutral-ghost'
                  }
                  onClick={(e) => {
                    sendEvent({
                      action: isLoggedIn ? 'grid_mode_set' : 'grid_mode',
                      category: gaEventCategory,
                    });
                    onOffersGridViewButtonClick(e);
                  }}
                />
                <Button
                  aria-label="Trocar visualização das ofertas para lista"
                  data-test-selector="change-to-offers-list-view"
                  icon={<Icon name="list" />}
                  size="size3"
                  type={
                    offersViewMode === OFFER.VIEW_MODES.LIST
                      ? 'neutral-stroke'
                      : 'neutral-ghost'
                  }
                  onClick={(e) => {
                    sendEvent({
                      action: isLoggedIn ? 'list_mode_set' : 'list_mode',
                      category: gaEventCategory,
                    });
                    onOffersListViewButtonClick(e);
                  }}
                />
              </>
            </div>
          </Box>
        )}
      </Box>
    );
  }
);

OffersOptions.displayName = 'OffersOptions';

OffersOptions.propTypes = {
  gaEventCategory: PropTypes.string,
  offersViewMode: PropTypes.string,
  onHideOffersLinkClick: PropTypes.func.isRequired,
  onOffersGridViewButtonClick: PropTypes.func.isRequired,
  onOffersListViewButtonClick: PropTypes.func.isRequired,
};

export default OffersOptions;
