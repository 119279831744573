import { useMemo } from 'react';

import Switch from 'shopper/components/Switch';

import useOffersFilters from 'hooks/useOffersFilters';
import useToast from 'hooks/useToast';

import { sendEvent } from 'lib/gtag';
import placeholder from 'lib/placeholder';

import { useUser } from 'providers/UserProvider';

const HideInternacionalOffersSwitch = () => {
  const { runCallbackIfLoggedIn } = useUser();
  const { showToast } = useToast();

  const {
    offersFiltersState,
    delOffersFilter,
    addOffersFilter,
    getOffersFilters,
  } = useOffersFilters();

  const [isInternationalOffersActive] = useMemo(() => {
    const { isInternationalOffersActive: internationalOffersFilter = true } =
      getOffersFilters() ?? {};

    return [internationalOffersFilter];
  }, [offersFiltersState]);

  const onSwitchChange = () => {
    sendEvent({
      action: isInternationalOffersActive
        ? 'hide_international_offers'
        : 'show_international_offers',
      category: `timeline_hide`,
    });

    runCallbackIfLoggedIn(() => {
      if (isInternationalOffersActive) {
        addOffersFilter({ type: 'international_offers' });
        showToast({
          text: placeholder('toasts.successes.offers.hideInternationalOffers'),
        });
        return;
      }

      delOffersFilter({ type: 'international_offers' });
      showToast({
        text: placeholder('toasts.successes.offers.showInternationalOffers'),
      });
    });
  };

  return (
    <Switch
      checked={isInternationalOffersActive}
      data-test-selector="toggle-international-offers-filter"
      label={placeholder('switches.toggleInternationalOffers')}
      onChange={onSwitchChange}
    />
  );
};

export default HideInternacionalOffersSwitch;
