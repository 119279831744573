import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { twJoin } from 'tailwind-merge';

import { noop } from 'lib/utils';

const FooterBottom = dynamic(() => import('./FooterBottom'));
const FooterMain = dynamic(() => import('./FooterMain'));

const FooterCloseButton = dynamic(() => import('./CloseButton'), {
  ssr: false,
});

const Footer = ({
  isActive = true,
  isFixed = false,
  isOpen = true,
  onClose = noop,
}) => {
  if (!isActive) {
    return null;
  }

  return (
    <footer
      className={twJoin(
        isFixed ? 'fixed left-0 z-50' : 'relative',
        isFixed &&
          (isOpen
            ? 'bottom-[var(--navbar-height)] md:bottom-0'
            : 'bottom-[-9999px]'),
        'flex w-full flex-col items-center border-t-3 border-solid border-t-primary-300 bg-neutral-high-100 pb-12 dark:bg-neutral-low-500 md:pb-0'
      )}
    >
      {isFixed && <FooterCloseButton onClick={() => onClose(false)} />}
      <FooterMain />
      <FooterBottom />
    </footer>
  );
};

Footer.propTypes = {
  isActive: PropTypes.bool,
  isFixed: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default memo(Footer);
