import { useMutation, useQuery } from '@tanstack/react-query';
import dynamic from 'next/dynamic';

import Icon from 'shopper/components/Icon';
import Link from 'shopper/components/Link';

import useDialog from 'hooks/useDialog';

import { sendEvent } from 'lib/gtag';
import placeholder from 'lib/placeholder';
import { wishlistQueryKeys } from 'lib/queryKeys';
import { noop } from 'lib/utils';

import { useLocalWishlist } from 'providers/LocalWishlistProvider';
import { useUser } from 'providers/UserProvider';

import {
  deleteOfferKeyword,
  getOfferKeywords,
  postAddOfferKeyword,
} from 'services/wishlist';

import useQueryClient from './useQueryClient';
import useToast from './useToast';

const LoginDialog = dynamic(() => import('components/LoginDialog'), {
  ssr: false,
});

const MESSAGE_ERROR_WHEN_KEYWORD_ALREADY_ADDED = 'already';
const MESSAGE_ERROR_WHEN_KEYWORD_INVALID = 'INVALID_KEYWORD';

function useWishlist(
  gaEventCategory,
  {
    gaEventLabel,
    showToastOnMutateSuccess = true,
    onAddKeywordToWishlistSuccess = noop,
  } = {}
) {
  const { showToast } = useToast();
  const { showDialog } = useDialog();
  const queryClient = useQueryClient();
  const { isLoggedIn, LoggedInUser } = useUser();
  const {
    addLocalOfferKeyword,
    removeLocalOfferKeyword,
    withLocalWishlistItems,
  } = useLocalWishlist();

  const { data: offerWishlist } = useQuery({
    queryKey: wishlistQueryKeys.offerKeywords(),
    queryFn: async ({ signal }) =>
      isLoggedIn
        ? getOfferKeywords({ signal })
        : withLocalWishlistItems(await getOfferKeywords({ signal })),
  });

  const getOfferKeywordId = (keywordDisplay) =>
    offerWishlist?.items.find((item) => item.keywordDisplay === keywordDisplay)
      ?.keywordId;

  const { mutate: mutateAddKeyword } = useMutation({
    mutationFn: ({ checkGeneric, keywordDisplay, keywordMax }) =>
      isLoggedIn
        ? postAddOfferKeyword({ checkGeneric, keywordDisplay, keywordMax })
        : addLocalOfferKeyword(keywordDisplay),
    onError: ({ message }) => {
      if (isLoggedIn) {
        showToast({
          iconLeft: <Icon name="info-sign" />,
          type: 'danger',
          text:
            message === MESSAGE_ERROR_WHEN_KEYWORD_ALREADY_ADDED &&
            message !== MESSAGE_ERROR_WHEN_KEYWORD_INVALID
              ? placeholder('toasts.errors.wishlist.keywordAlreadyAdded')
              : placeholder('toasts.errors.wishlist.couldNotAddKeyword'),
        });
      }
    },
    onMutate: () => {
      sendEvent({
        action: isLoggedIn
          ? 'keyword_add_attempt'
          : 'keyword_add_attempt_unlogged',
        category: gaEventCategory,
        label: gaEventLabel,
      });

      if (!isLoggedIn) {
        showDialog(LoginDialog);
      }
    },
    onSuccess: (
      keyword,
      { mutateRemoveKeyword, keywordDisplay, showToastOnSuccess = true }
    ) => {
      sendEvent({
        action: isLoggedIn ? 'keyword_added' : 'keyword_added_unlogged',
        category: gaEventCategory,
        label: gaEventLabel,
      });

      queryClient.setQueryData(
        wishlistQueryKeys.offerKeywords({
          userId: LoggedInUser?.userId ?? null,
        }),
        {
          ...offerWishlist,
          items: [
            isLoggedIn
              ? keyword
              : {
                  keywordDisplay,
                  keywordMax: -1,
                  keywordMin: 0,
                  keywordCategoryId: 0,
                  keywordTop: false,
                },
            ...offerWishlist.items,
          ],
          suggestions: offerWishlist.suggestions.filter(
            (suggestion) => suggestion !== keywordDisplay
          ),
        }
      );

      if (isLoggedIn && showToastOnSuccess && showToastOnMutateSuccess) {
        showToast({
          text: placeholder('toasts.successes.wishlist.keywordAdded'),
          type: 'action',
          link: (
            <Link
              onClick={() => {
                sendEvent({
                  action: isLoggedIn
                    ? 'undo_added_keyword'
                    : 'undo_added_keyword_unlogged',
                  category: gaEventCategory,
                  label: gaEventLabel,
                });
                mutateRemoveKeyword({
                  keywordDisplay,
                  showToastOnSuccess: false,
                });
              }}
            >
              Desfazer
            </Link>
          ),
        });
      }

      onAddKeywordToWishlistSuccess();
    },
  });

  const { mutate: mutateRemoveKeyword } = useMutation({
    mutationFn: ({ keywordDisplay }) =>
      isLoggedIn
        ? deleteOfferKeyword({ keywordId: getOfferKeywordId(keywordDisplay) })
        : removeLocalOfferKeyword(keywordDisplay),
    onError: () => {
      showToast({
        text: placeholder('toasts.errors.wishlist.couldNotRemoveKeyword'),
        type: 'danger',
      });
    },
    onMutate: () => {
      sendEvent({
        action: 'keyword_remove_attempt',
        category: gaEventCategory,
        label: gaEventLabel,
      });
    },
    onSuccess: (_, { keywordDisplay, showToastOnSuccess = true }) => {
      sendEvent({
        action: isLoggedIn ? 'keyword_removed' : 'keyword_removed_unlogged',
        category: gaEventCategory,
        label: gaEventLabel,
      });

      queryClient.setQueryData(
        wishlistQueryKeys.offerKeywords({
          userId: LoggedInUser?.userId ?? null,
        }),
        {
          ...offerWishlist,
          items: offerWishlist.items.filter(
            (item) => item.keywordDisplay !== keywordDisplay
          ),
        }
      );

      if (isLoggedIn && showToastOnSuccess && showToastOnMutateSuccess) {
        showToast({
          text: placeholder('toasts.successes.wishlist.keywordRemoved'),
          type: 'action',
          link: (
            <Link
              onClick={() => {
                sendEvent({
                  action: isLoggedIn
                    ? 'undo_removed_keyword'
                    : 'undo_removed_keyword_unlogged',
                  category: gaEventCategory,
                  label: gaEventLabel,
                });
                mutateAddKeyword({
                  keywordDisplay,
                  showToastOnSuccess: false,
                });
              }}
            >
              Desfazer
            </Link>
          ),
        });
      }
    },
  });

  return {
    addOfferWishlistKeyword: ({
      checkGeneric = false,
      keywordDisplay,
      keywordMax = null,
    }) =>
      /**
       * `mutateRemoveKeyword` is passed as a param to prevent
       * a circular dependency.
       */
      mutateAddKeyword({
        checkGeneric,
        keywordDisplay,
        keywordMax,
        mutateRemoveKeyword,
      }),
    removeOfferWishlistKeyword: mutateRemoveKeyword,
    offerWishlist,
  };
}

export default useWishlist;
