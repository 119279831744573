import LazyHydration from 'components/LazyHydration';

import GooglePublisherTagAdSlot from '.';

const LazyHydratedGooglePublisherTagAdSlot = (props) => (
  <LazyHydration placeholderSize={{ height: 304 }}>
    <GooglePublisherTagAdSlot {...props} />
  </LazyHydration>
);

export default LazyHydratedGooglePublisherTagAdSlot;
