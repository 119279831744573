import { dispatch } from 'lib/eventManager';

import TOAST from 'constants/toast';

const useToast = () => {
  const showToast = (props) => dispatch(TOAST.EVENTS.SHOW_TOAST, props);

  const hideToasts = () => dispatch(TOAST.EVENTS.HIDE_ALL_TOASTS);

  return { showToast, hideToasts };
};

export default useToast;
